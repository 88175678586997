import Link from 'next/link'
import { HEADER_LOGO_URL } from '~constants'
import { ConditionalWrapper } from '~components'
import styles from 'components/Header/index.module.scss'
import Image from 'next/image'
import { useWindowSize } from '~hooks'
import { useEffect, useState } from 'react'

interface IBrandLogo {
  logo?: {
    url?: string
    altUrl?: string
  }
  isPreview: boolean
  useAltLogo?: boolean
}

export const BrandLogo = ({ logo, isPreview, useAltLogo = false }: IBrandLogo) => {
  const { isBreakpoint: isBelowBreakpointLg } = useWindowSize('lg', '<=')
  const [displayAlt, setDisplayAlt] = useState<boolean>(false)

  useEffect(() => {
    if (useAltLogo && logo?.altUrl && isBelowBreakpointLg) {
      setDisplayAlt(true)
    } else {
      setDisplayAlt(false)
    }
  }, [isBelowBreakpointLg])

  return (
    <>
      {displayAlt && logo?.altUrl ? (
        <div className={styles['brand-logo-alt']}>
          <div className={'image-container'}>
            <ConditionalWrapper condition={!isPreview} wrapper={children => <Link href={HEADER_LOGO_URL}>{children}</Link>}>
              <>
                <Image src={logo.altUrl} alt="Sphere" priority id="alt-logo" width={120} height={23} />
              </>
            </ConditionalWrapper>
          </div>
        </div>
      ) : (
        <div className={styles['brand-logo']}>
          {logo?.url && (
            <div className={'image-container'}>
              <ConditionalWrapper condition={!isPreview} wrapper={children => <Link href={HEADER_LOGO_URL}>{children}</Link>}>
                <>
                  <Image src={logo.url} alt="Sphere" fill priority id="logo" />
                </>
              </ConditionalWrapper>
            </div>
          )}
        </div>
      )}
    </>
  )
}
