import axios from 'axios'

export const CreatePreviewURL = async (title: string): Promise<string> => {
  // filePath construction will change if/when we add folders
  const filePath = `upload/${title}`
  const type = 'inline'

  return axios
    .get('/api/downloadfile', { params: { filePath, type } })
    .then(res => {
      return res.data.url
    })
    .catch((error: Error) => {
      throw error
    })
}
