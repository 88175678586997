import React, { ClipboardEvent, ClipboardEventHandler, useCallback, useEffect, useRef, useState } from 'react'
import styles from './index.module.scss'
import { EmailList } from './EmailList'
import { EMAIL_REGEX, SHARING_MSG_MAX_CHARS, SHARING_SPECIAL_KEYS, SHARING_MAX_EMAILS } from '~constants'
import { IEmailItem, IRecipientsModalBody } from '~interfaces'
import { RecipientsModalControlPanel } from './RecipientsModalControlPanel'
import { UserAccessList } from './UserAccessList'
import { GetUserAccessList } from '~services'
import { STATUS_CODES } from '~enums'

export const RecipientsModalBody = ({ fileName, videoId, shareUrl, handleShareResponse }: IRecipientsModalBody) => {
  const [emails, setEmails] = useState<IEmailItem[]>([])
  const emailsRef = useRef(emails)
  const [selectedEmail, setSelectedEmail] = useState<IEmailItem>()
  const [selectedEmailIdx, setSelectedEmailIdx] = useState<number>()
  const selectedEmailIdxRef = useRef(selectedEmailIdx)
  const [numValidEmails, setNumValidEmails] = useState<number>(0)
  const numValidEmailsRef = useRef(numValidEmails)
  const [notifyPeople, setNotifyPeople] = useState<boolean>(false)
  const [message, setMessage] = useState('')
  const [accessList, setAccessList] = useState<string[]>([])
  const [isOverMsgLimit, setIsOverMsgLimit] = useState<boolean>(false)

  useEffect(() => {
    GetUserAccessList({ videoId: videoId.toString() })
      .then(res => {
        if (res.status === STATUS_CODES.NO_USERS_FOUND) {
          setAccessList([])
        }
        if (res.status === STATUS_CODES.SUCCESSFUL_USERS_ACCESS_LIST) {
          setAccessList(res.emails)
        }
      })
      .catch(err => console.error(err))
  }, [videoId])

  const removeEmailByIdx = useCallback((deletedIndex: number, emails: IEmailItem[], numValidEmails: number) => {
    const deletedEmail: IEmailItem = emails[deletedIndex]
    const newEmails = emails.filter((_, idx) => idx !== deletedIndex)
    setEmails(newEmails)
    if (deletedEmail.isValid) {
      const newNum = numValidEmails - 1
      setNumValidEmails(newNum)
    }
  }, [])

  useEffect(() => {
    const handleBackspace = (event: KeyboardEvent) => {
      const { key } = event
      const editableDiv = document.getElementById('text-input')
      if (key === 'Backspace' && selectedEmailIdxRef.current !== undefined && !editableDiv?.textContent?.length) {
        removeEmailByIdx(selectedEmailIdxRef.current, emailsRef.current, numValidEmailsRef.current)
        setSelectedEmail(undefined)
        setSelectedEmailIdx(undefined)
      }
    }

    document.addEventListener('keydown', handleBackspace)

    return () => {
      document.removeEventListener('keydown', handleBackspace)
    }
  }, [removeEmailByIdx])

  useEffect(() => {
    // Update the ref to the current state on each render
    numValidEmailsRef.current = numValidEmails
    emailsRef.current = emails
    selectedEmailIdxRef.current = selectedEmailIdx
  }, [numValidEmails, emails, selectedEmailIdx])

  const handleKeyUp = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      const validateEmail = (email: string) => {
        const regexTester = new RegExp(EMAIL_REGEX)
        return regexTester.test(email) && email.length < 255
      }

      const { key } = event
      const editableDiv = document.getElementById('text-input')

      const addNewEmail = (email: string, isValid: boolean) => {
        const newEmail: IEmailItem = { email, isValid }
        setEmails([...emails, newEmail])
        if (isValid) {
          const newNum = numValidEmails + 1
          setNumValidEmails(newNum)
        }
      }

      //When there is new text and less than 50 added emails
      if (editableDiv && numValidEmails < SHARING_MAX_EMAILS) {
        if (SHARING_SPECIAL_KEYS.includes(key) && editableDiv.textContent?.length) {
          const newEmail = editableDiv.textContent.trim()
          const isValid = validateEmail(newEmail)
          addNewEmail(newEmail, isValid)
          editableDiv.textContent = ''
        }
        //When there is new text and 50 or more added emails. This should never happen.
      } else if (editableDiv && numValidEmails >= SHARING_MAX_EMAILS) {
        console.log(`${SHARING_MAX_EMAILS} is the max!`)
      }
    },
    [emails, numValidEmails]
  )

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      const { key } = event
      //We prevent these keys from adding unwanted characters to the text
      if (SHARING_SPECIAL_KEYS.includes(key)) {
        event.preventDefault()
      }

      const editableDiv = document.getElementById('text-input')
      //When there are added emails but no new text,
      if (emails.length) {
        //And a user hits 'Backspace'
        if (key === 'Backspace') {
          //If no email is selected/highlighted, the last email will be selected
          if (!editableDiv?.textContent && !selectedEmail?.email && !selectedEmailIdx) {
            const idx = emails.length - 1
            const selEmail = emails[idx]
            setSelectedEmailIdx(idx)
            setSelectedEmail(selEmail)
            event.stopPropagation()
            return
          }
        }
      }
    },
    [emails, selectedEmail, selectedEmailIdx]
  )

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { target } = e
    const newMessage = (target as HTMLTextAreaElement).value
    // Only allow typing if the length is within the limit
    if (newMessage.length <= SHARING_MSG_MAX_CHARS) {
      setIsOverMsgLimit(false)
      setMessage(newMessage) // Keeps content at 150 characters without adding more
      return
    }
    if (newMessage.length > SHARING_MSG_MAX_CHARS) {
      setIsOverMsgLimit(true)
    }
  }

  const insertText = (txt: string) => {
    const selection = window.getSelection()

    if (!selection) {
      return
    }

    const editableDiv = document.getElementById('text-input')
    const text = editableDiv!.textContent

    //If text does exist in the editable div, we need to handle how to insert the pasted text and where the cursor appears
    const before = Math.min(selection.focusOffset, selection.anchorOffset)
    const after = Math.max(selection.focusOffset, selection.anchorOffset)
    const afterStr = text!.substring(after)

    editableDiv!.textContent = text!.substring(0, before) + txt + afterStr

    selection.removeAllRanges()
    const range = document.createRange()

    range.setStart(editableDiv!.childNodes[0], before + txt.length)
    range.setEnd(editableDiv!.childNodes[0], before + txt.length)

    selection.addRange(range)
  }

  const handlePaste: ClipboardEventHandler = (event: ClipboardEvent) => {
    event.preventDefault()
    const text = event.clipboardData ? event.clipboardData.getData('text') : ''
    insertText(text)
  }

  return (
    <div className={styles['modal-body-container']}>
      <div id="email-input" className={styles['email-input']}>
        <EmailList
          emails={emails}
          removeEmail={removeEmailByIdx}
          selectedEmail={selectedEmail}
          setSelectedEmail={setSelectedEmail}
          selectedEmailIdx={selectedEmailIdx}
          setSelectedEmailIdx={setSelectedEmailIdx}
          numValidEmails={numValidEmails}
        />
        <div
          id="text-input"
          data-testid="text-input"
          className={styles['text-input']}
          contentEditable={numValidEmails < SHARING_MAX_EMAILS ? 'true' : 'false'}
          data-placeholder={emails.length ? '' : 'External emails...'}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
          onPaste={handlePaste}
        />
      </div>
      {notifyPeople && numValidEmails > 0 && (
        <div>
          <textarea
            id="message-input"
            data-testid="message-input"
            value={message}
            onChange={handleMessageChange}
            className={`${styles['message-div']} ${isOverMsgLimit ? styles['error'] : ''}`}
            placeholder={message.length ? '' : 'Message (Optional)'}
          />
          {isOverMsgLimit && <p className={styles['character-limit-warning']}>Limit to 150 characters</p>}
        </div>
      )}
      {numValidEmails > 0 && (
        <div className={styles['notify-details-container']}>
          <div className={styles['notify-left']}>
            <div className={styles['notify-checkbox']}>
              <input type="checkbox" checked={notifyPeople} onChange={() => setNotifyPeople(!notifyPeople)} />
              <label className={styles['notify-label']}>Notify people</label>
            </div>
          </div>
          <div className={styles['notify-right']}>
            <div className={styles['num-share-status']}>Share with {numValidEmails} people</div>
          </div>
        </div>
      )}
      {accessList.length ? <UserAccessList accessList={accessList} /> : null}
      <RecipientsModalControlPanel
        sendNotification={notifyPeople}
        emails={emails}
        message={message}
        fileName={fileName}
        videoId={videoId}
        shareUrl={shareUrl}
        enableShareButton={numValidEmails > 0}
        handleShareResponse={handleShareResponse}
      />
    </div>
  )
}
