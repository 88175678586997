import { STATUS_CODES, SHARING_RESULT_MODAL_TYPE } from '~enums'
import styles from './index.module.scss'
import { CopyAndShareButton, LinkIcon, SimpleShareButton } from '~elements'
import { IEmailItem, IRecipientsModalControlPanel, IShareRequestBody } from '~interfaces'
import { SharePreview } from '~services'
import { getCognitoUserInfo } from '~utils'
import { useState } from 'react'

export const RecipientsModalControlPanel = ({
  emails,
  sendNotification,
  message,
  fileName,
  videoId,
  shareUrl,
  enableShareButton,
  handleShareResponse
}: IRecipientsModalControlPanel) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const getValidEmails = (emails: IEmailItem[]) => {
    return emails.flatMap(email => (email.isValid ? [email.email] : []))
  }

  const sendShareRequest = async (validEmails: string[], cognitoUserName: string) => {
    setIsDisabled(true)
    const shareRequest: IShareRequestBody = {
      allowedTimesOfDay: [],
      allowedVantagePoints: [],
      fileName,
      emails: validEmails,
      videoId,
      sharerName: `${cognitoUserName}`,
      sendNotification,
      notificationMessage: message?.length ? message : undefined
    }
    return await SharePreview(shareRequest)
  }

  const handleShare = async () => {
    try {
      const cognitoUserInfo = await getCognitoUserInfo()
      if (!cognitoUserInfo) {
        throw new Error('Unknown error in fetching user info before sharing.')
      }

      const validEmails = getValidEmails(emails)
      if (!validEmails.length) {
        throw new Error('No valid emails.')
      }

      const response: { status: number; expiration: string } = await sendShareRequest(validEmails, cognitoUserInfo.name)
      if (
        response.status === STATUS_CODES.SUCCESSFUL_SHARED_EMAILS_SENT ||
        response.status === STATUS_CODES.SUCCESSFUL_CREATOR_NO_SHARED_NOTIFICATION
      ) {
        const modalType =
          response.status === STATUS_CODES.SUCCESSFUL_SHARED_EMAILS_SENT
            ? SHARING_RESULT_MODAL_TYPE.SUCCESS
            : SHARING_RESULT_MODAL_TYPE.SUCCESS_WITH_COPY_LINK
        const expirationDate = new Date(response.expiration).toLocaleDateString('en-us', { year: 'numeric', month: 'long', day: 'numeric' })
        handleShareResponse(modalType, validEmails.length, expirationDate)
      }
    } catch (error) {
      handleShareResponse(SHARING_RESULT_MODAL_TYPE.API_FAILED)
      setIsDisabled(false)
      if (error instanceof Error) {
        throw new Error(error.message)
      } else {
        console.error(`Unable to share ${fileName}. Please try again later.`)
      }
    }
  }

  return (
    <div className={styles['modal-control-panel']}>
      {/* <div className={styles['access-section']}>
        <div className={styles['item']}>
          <span className={styles['name']}>Domain Access</span>
          <span className={styles['number']}>0/3</span>
        </div>
        <div className={styles['item']}>
          <span className={styles['name']}>Vantage Point</span>
          <span className={styles['number']}>32/32</span>
        </div>
        <div className={styles['item']}>
          <span className={styles['name']}>Times of Day</span>
          <span className={styles['number']}>3/3</span>
        </div>
      </div>
      <div className={styles['divider']}></div> */}
      <div className={styles['panel-buttons']}>
        <CopyAndShareButton
          displayText={'Copy link'}
          tooltipText={'Link Copied!'}
          styles={styles}
          shareTitle={`${fileName} | Exo Web Preview`}
          Icon={LinkIcon}
          url={shareUrl}
          useNavigatorShare={false}
        />
        <SimpleShareButton
          styles={styles}
          handleClick={handleShare}
          displayText={'Share'}
          displayIcon={false}
          disabled={!enableShareButton || isDisabled}
        />
      </div>
    </div>
  )
}
