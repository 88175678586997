'use client'

import { useState } from 'react'
import styles from './index.module.scss'
import { EncryptedIcon, Modal, Heading, ResultModal, Paragraph } from '~elements'
import { EMAIL_REGEX } from '~constants'
import { VerifyRecipient } from '~services'
import { IPreviewViewerForm, IVerifyRequestBody } from '~interfaces'
import { RESULT_MODAL_TYPE, STATUS_CODES } from '~enums'

export const PreviewViewerForm = ({ setAccessDenied, displayPreview, displayLoginForCreator }: IPreviewViewerForm) => {
  const [email, setEmail] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [error, setError] = useState('')
  const [disabled, setDisabled] = useState<boolean>(false)
  const [verificationFailed, setVerificationFailed] = useState<boolean>(false)

  const validateEmail = (email: string) => {
    const regexTester = new RegExp(EMAIL_REGEX)

    if (!email) {
      return 'This field is required!'
    }
    if (!regexTester.test(email) || email.length > 254) {
      return 'Invalid email address'
    }
    return ''
  }

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setIsSubmitted(true)

    let isValidated = false
    const errorMessage = validateEmail(email)
    if (errorMessage.length) {
      setError(errorMessage)
    } else {
      isValidated = true
    }

    if (isValidated) {
      setDisabled(true)

      const request: IVerifyRequestBody = {
        email
      }

      VerifyRecipient(request)
        .then(res => {
          if (res.status === STATUS_CODES.SERVER_ERROR) {
            setAccessDenied(true)
          }
          if (res.status === STATUS_CODES.SUCCESSFUL_RECIPIENT_VERIFICATION) {
            displayPreview()
          }
          if (res.status === STATUS_CODES.SUCCESSFUL_CREATOR_ACCOUNT_VERIFICATION) {
            displayLoginForCreator()
          }
        })
        .catch(error => {
          console.error(error)
          setVerificationFailed(true)
        })
        .finally(() => setDisabled(false))
    }
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
    if (isSubmitted) {
      setError('')
    }
  }

  return (
    <>
      <Modal isOpen={true} handleModalClose={() => {}} showCloseButton={false}>
        <div className={styles['email-modal']}>
          <div className={styles['modal-header']}>
            <EncryptedIcon />
            <Heading level={4} className={styles['title']}>
              Enter email address
            </Heading>
            <p>Please enter your email address.</p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className={styles['input-wrapper']}>
              <input
                type="text"
                id="email"
                value={email}
                onChange={handleEmailChange}
                placeholder=" "
                className={`${styles['email-input']} ${error ? styles['input-error'] : ''}`}
                aria-label="Email"
              />
              <label htmlFor="email" className={`${styles['floating-label']} ${error ? styles['error'] : ''}`}>
                Email
              </label>
            </div>
            {isSubmitted && error && <span className={styles['error-message']}>{error}</span>}
            <div className={styles['cta-container']}>
              <button type="submit" className={styles['continue-button']} disabled={disabled}>
                Continue
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <ResultModal
        isOpen={verificationFailed}
        type={RESULT_MODAL_TYPE.FAILED}
        showActionButtons={true}
        showCloseOutButton={true}
        handleModalClose={() => setVerificationFailed(false)}
      >
        <div className={styles['verification-failed-wrapper']}>
          <Heading level={4} className={styles['heading']}>
            Verification Error
          </Heading>
          <Paragraph
            fontSize="md"
            justify={'center'}
            text={{ html: 'We apologize, but the verification function is currently unavailable. Please try again later.' }}
          />
        </div>
      </ResultModal>
    </>
  )
}
