'use client'

import RenderStreamingManager from 'render-streaming-client/build/RenderStreamingManager'
import DownloadVideoCommand from 'render-streaming-client/build/command/DownloadVideoCommand'
import SetTimeOfDayCommand from 'render-streaming-client/build/command/SetTimeOfDayCommand'
import ApplyVantagePointCommand from 'render-streaming-client/build/command/ApplyVantagePointCommand'
import ResetPreviewRuntimeCommand from 'render-streaming-client/build/command/ResetPreviewRuntimeCommand'
// import CustomCommand from 'render-streaming-client/build/command/CustomCommand'
import styles from './index.module.scss'
import { useEffect, useState } from 'react'
import type { TRenderStreamingConfiguration } from 'render-streaming-client/build/ConfigurationTypes'
import { AccessDeniedModal, PlayPauseButton } from '~elements'
import { usePreviewStore } from 'store'
import { useSearchParams, useRouter } from 'next/navigation'
import { PREVIEW_DISCONNECT_TYPE, STATUS_CODES } from '~enums'
import { DEFAULT_TIME_OF_DAY_UUID, DEFAULT_VANTAGE_POINT_UUID, PREVIEW_LOGIN_PATH } from '~constants'
import { categorizeVantagePointData, getTimesOfDayImageUrls, handlePreviewDisconnect } from '~utils'
import { CreatePreviewURL, GetTimesOfDay, GetVantagePoints, GetVideo, GetVideos } from '~services'
import { ICustomPixelEvent, IPreviewVideo } from '~interfaces'

const configuration: TRenderStreamingConfiguration = {
  matchmaker: {
    port: parseInt(`${process.env.NEXT_PUBLIC_MATCHMAKER_PORT}`) || 3000,
    domain: `${process.env.NEXT_PUBLIC_MATCHMAKER_URL}`,
    enabled: true,
    secure: true
  },
  signaling: {
    secure: true
  }
}

export const RenderClient = ({ isRecipient, isCreator }: { isRecipient: boolean; isCreator: boolean }) => {
  const [streaming, setStreaming] = useState<boolean>(false)
  const [manager, setManager] = useState<RenderStreamingManager | null>(null)
  const [playing, setPlaying] = useState<boolean>(false)
  const [accessDenied, setAccessDenied] = useState(false)
  const {
    setRenderingManager,
    renderingManager,
    selectedVideo,
    setSelectedVideo,
    resetRenderingManager,
    allVPCategories,
    setAllVPCategories,
    setAllVantagePoints,
    allTimesOfDay,
    setAllTimesOfDay
  } = usePreviewStore()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isReady, setIsReady] = useState<boolean>(false)
  const router = useRouter()
  const searchParams = useSearchParams()
  const queryId = searchParams.get('id')

  useEffect(() => {
    const sixtyMinutes = 1000 * 60 * 60
    const timer = setTimeout(() => {
      handlePreviewDisconnect({
        renderingManager,
        resetRenderingManager,
        type: PREVIEW_DISCONNECT_TYPE.IDLE_TIMEOUT
      })
      router.push(PREVIEW_LOGIN_PATH)
    }, sixtyMinutes)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  useEffect(() => {
    // Before creating a renderStreamingManager we need to make sure a selectedVideo exists.
    if (!selectedVideo?.url && queryId && !isReady) {
      if (isCreator) {
        GetVideos()
          .then(async videos => {
            const currVideo = videos.data.find((video: IPreviewVideo) => {
              if (!queryId) {
                throw Error('Missing query parameter id')
              }

              return video.id == queryId
            })

            if (!currVideo) {
              throw Error('No video could be found.')
            }

            const preSignedUrl = await CreatePreviewURL(currVideo.title)
            setSelectedVideo({ ...currVideo, url: preSignedUrl })
            setIsReady(true)
          })
          .catch(error => {
            console.error(error)
          })
      } else if (isRecipient) {
        const request = {
          videoId: queryId
        }
        GetVideo(request)
          .then(async response => {
            if (response.data.status === STATUS_CODES.GET_VIDEO_UNAUTHORIZED) {
              setAccessDenied(true)
              return
            }

            const video = response.data.video[0]
            const { vantagePoints, timesOfDay } = response.data
            if (!video) {
              throw Error('No video could be found.')
            }

            const preSignedUrl = await CreatePreviewURL(video.title)
            const categorizedVPs = categorizeVantagePointData(vantagePoints)
            const timesOfDayWithImages = getTimesOfDayImageUrls(timesOfDay)

            setSelectedVideo({ ...video, url: preSignedUrl })
            setAllVantagePoints(vantagePoints)
            setAllVPCategories(categorizedVPs)
            setAllTimesOfDay(timesOfDayWithImages)
            setIsReady(true)
          })
          .catch(error => console.error(error))
      }
    } else if (!isReady && selectedVideo?.url) {
      setIsReady(true)
    }
  }, [])

  useEffect(() => {
    if (isReady) {
      const fetchVPAndToDData = async () => {
        if (!allVPCategories?.length) {
          await getAllVantagePoints()
        }
        if (!allTimesOfDay?.length) {
          await getAllTimesOfDay()
        }
      }
      fetchVPAndToDData().catch(error => console.error(error))

      const renderStreamingManager = new RenderStreamingManager(configuration)

      ;(renderStreamingManager._pixelStreaming.videoElementParent.firstElementChild?.nextElementSibling as HTMLElement)!.style.top = '0'

      setManager(renderStreamingManager)

      return () => {
        renderStreamingManager
          ?.disconnect()
          .then(() => console.log('[UNMOUNTED]: RenderClient has unmounted and disconnected current connection.'))
          .catch(err => console.error(`[UNMOUNTED]: There was this ${err} error when disconnecting.`))
        renderStreamingManager._pixelStreaming.videoElementParent.remove()
      }
    }
  }, [isReady])

  useEffect(() => {
    if (manager && isReady) {
      manager.onCommandResponse('get-preview-engine-build-version-command', response => {
        const parseResponse = JSON.parse(response)
        console.log('SPE Version: ', parseResponse.Payload.BuildVersionInfo)
      })

      manager.onStatusChanged((oldStatus, newStatus) => {
        console.log('RenderStreaming status changed', oldStatus, newStatus)
      })

      /**
       * Listen for Render Streaming events!
       */
      //
      // Signaling Events
      //
      manager.onSignalingConnection(() => {
        console.log('Connected to Signaling Server')
      })
      manager.onSignalingDisconnect(() => {
        console.log('Disconnected from Signaling Server')
      })
      manager.onWebRtcConnection(() => {
        console.log('Connected to Unity APP')
      })
      //
      // WebRTC Events
      //
      manager.onWebRtcDisconnect(() => {
        console.log('Disconnected from Unity APP')
      })
      manager.onWebRtcDatachannelOpen(() => {
        console.log('Data channel to Unity APP opened')
        //The data channel needs a little bit of time before it is ready to receive a command
      })

      manager.onWebRtcDatachannelClosed(() => {
        console.log('Data channel to Unity APP closed!')
      })
      //
      // Matchmaker Events
      //
      manager.onMatchmakerConnection(() => {
        console.log('Connected to Matchmaker')
      })
      // Event dispatched at signaling match
      manager.onMatchmakerSignalingDataReceived(signalingUrl => {
        console.log(`Matched signaling server connection at ${signalingUrl}`)
      })
      manager.onMatchmakerDisconnect(reason => {
        console.log(`Disconnected from Matchmaker: ${reason}`)
      })

      console.log('hooking ready')
      manager.onRenderStreamingReady(() => {
        setTimeout(function () {
          downloadAndPlayVideo()
          setStreaming(true)
        }, 1000)
      })

      manager._pixelStreaming.addEventListener('webRtcDisconnected', resetPixelStreaming)

      console.log('FiringBeginStream')
      beginStreaming()
      manager._pixelStreaming.emitUIInteraction({})

      return () => {
        manager?._pixelStreaming.removeEventListener('webRtcDisconnected', resetPixelStreaming)
      }
    }
  }, [manager])

  const resetPixelStreaming = (e: ICustomPixelEvent) => {
    manager?._pixelStreaming.removeEventListener('webRtcDisconnected', resetPixelStreaming)

    if (e.data.eventString === 'too many connections. max: 2, current: 2') {
      manager
        ?.disconnect()
        .then(() => {
          manager._pixelStreaming.videoElementParent.remove()

          console.warn(`Resetting rendering streaming manager due to ${e.data.eventString}`)

          const renderStreamingManager = new RenderStreamingManager(configuration)

          ;(renderStreamingManager._pixelStreaming.videoElementParent.firstElementChild?.nextElementSibling as HTMLElement)!.style.top = '0'

          setManager(renderStreamingManager)
        })
        .catch(error => {
          console.error(`Unable to disconnect this connection ${error}`)
          window.location.replace('/preview')
        })
    }
  }

  const beginStreaming = () => {
    if (!manager) {
      throw new Error('Unable to start streaming because streaming manager is not defined.')
    }

    manager.startStreaming()
  }

  const applyDefaultSettings = () => {
    if (!manager) {
      throw new Error('Unable to change settings because streaming manager is not defined.')
    }

    const applyDefaultTimeOfDay = new SetTimeOfDayCommand(DEFAULT_TIME_OF_DAY_UUID)
    const applyDefaultVantagePoint = new ApplyVantagePointCommand(DEFAULT_VANTAGE_POINT_UUID)
    // const getPreviewEngineBuildVersionCommand = new CustomCommand('get-preview-engine-build-version-command', {})

    manager.executeCommand(applyDefaultTimeOfDay)
    manager.executeCommand(applyDefaultVantagePoint)

    manager.onCommandResponse(applyDefaultVantagePoint, data => {
      console.log('Default vantage point', JSON.parse(data))
    })

    // This command is not recognized and will throw errors.
    // manager.executeCommand(getPreviewEngineBuildVersionCommand)
  }

  const downloadAndPlayVideo = () => {
    console.log('Trying to start a download!!')
    if (!manager) {
      throw new Error('Unable to play video because streaming manager is not defined.')
    }
    if (selectedVideo?.url) {
      const downloadVideo = new DownloadVideoCommand(selectedVideo.url)
      manager.onCommandResponse(downloadVideo, data => {
        const response = JSON.parse(data)

        // These two strings are returned by the render-streaming module when a file has finished downloading.
        if (response.Payload.downloadStatus && response.Payload.downloadStatus.match(/(ALREADY DOWNLOADED|DOWNLOAD COMPLETED)/) !== null) {
          const ResetVideoCommand = new ResetPreviewRuntimeCommand()
          manager.onCommandResponse(ResetVideoCommand, data => {
            // Use zustand global store to set the manager to be available for other components.
            console.log(`Running reset command: ${data}`)
            setRenderingManager(manager)
            applyDefaultSettings()
          })
          manager.executeCommand(ResetVideoCommand)

          setPlaying(true)
          setTimeout(() => setIsLoading(false), 1500)
        }
      })
      manager.executeCommand(downloadVideo)
    }
  }

  const getAllVantagePoints = async () => {
    try {
      const {
        data: { categorizedData, rawData }
      } = await GetVantagePoints({ isRecipient, videoId: queryId ? queryId : undefined })
      setAllVPCategories(categorizedData)
      setAllVantagePoints(rawData)
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message)
      }

      throw new Error('Unknown error in fetching vantage points.')
    }
  }

  const getAllTimesOfDay = async () => {
    try {
      const { data: timesOfDayData } = await GetTimesOfDay({ isRecipient, videoId: queryId ? queryId : undefined })
      setAllTimesOfDay(timesOfDayData)
    } catch (err) {
      if (err instanceof Error) {
        throw new Error(err.message)
      }

      throw new Error('Unknown error in fetching times of day.')
    }
  }

  // FOR DEBUGGING:
  // manager.onAnyCommand(data => {
  //   const jsonObject = JSON.parse(data)
  //   console.log('jsonObject:', jsonObject)
  // })

  return (
    <div className={styles['render-client-container']}>
      <div id="container" className={styles['video-container']}>
        <div id="player" className={styles['video-player']} />
        {streaming ? <PlayPauseButton playing={playing} streaming={streaming} setPlaying={setPlaying} /> : null}
      </div>
      {isLoading ? (
        <div className={styles['loading__overlay']}>
          <p>LOADING...</p>
        </div>
      ) : null}
      {accessDenied ? <AccessDeniedModal /> : null}
    </div>
  )
}
