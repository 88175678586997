export const disableBodyScroll = (modalOpen: boolean) => {
  const html = document.querySelector('html')
  const body = document.querySelector('body')

  if (!html || !body) {
    return
  }

  if (modalOpen) {
    html.style.overflowY = 'hidden'
    body.style.overflowY = 'hidden'
    // required for iOS
    html.style.touchAction = 'none'
    body.style.touchAction = 'none'

    // prevents scrolling on ipad
    html.style.height = '100%'
    body.style.height = '100%'
  } else {
    html.style.removeProperty('overflow-y')
    body.style.removeProperty('overflow-y')
    html.style.touchAction = 'auto'
    body.style.touchAction = 'auto'

    html.style.height = 'initial'
    body.style.height = 'initial'
  }
}
