import { isProd } from '~constants'
import { GA_EVENT, GA_EVENT_TYPE, PAGE } from '~enums'

export interface IgaDLParams {
  event: GA_EVENT
  cognito_user_id?: string
  content_filename?: string
  page?: PAGE
  event_date?: string
  venue_name?: string
  event_type?: GA_EVENT_TYPE
  vantage_category?: string
  vantage_group?: string
  vantage_point?: string
  type?: string
  time_of_day?: string
}

export const gaDLPush = (params: IgaDLParams) => {
  if (typeof window !== 'undefined' && isProd) {
    //@ts-expect-error ts(2339)
    window.dataLayer.push(params)
  } else {
    console.debug('/// gaDLPush params: ', params)
  }
}
